.disSis-p {
    padding-left: 0px;
    padding-right: 60px;
    margin-left: -10px;
}

.card-media {
    height: 260px;
    width: "100%";
    padding-top: '70.25%', ;
    padding-right: '60.25%';
}

.card-media-sisto {
    height: 250px;
    padding-top: '50.25%';
    padding-left: '45.25%';
    padding-right: '45.25%';
}


@media (max-width: 320px) {
    #label-div {
        font-size: 15px;
    }

    .disSis-p {
        padding-left: 0px;
        padding-right: 50px;
        margin-left: -15px;
    }

    .card-media {
        height: 230px;
        width: "100%";
    }

    .card-media-sisto {
        height: 230px;
    }
}