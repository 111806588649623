.Title {
  position: absolute;
  bottom: 20px;
  /* Ajusta esto según la posición vertical deseada */
  left: 30px;
  /* Ajusta esto según la posición horizontal deseada */
  width: 200px;
  /* Ajusta el ancho del componente */
  height: 150px;
  /* Ajusta la altura del componente */
 
}

.fixed-container {
  position: relative;
  max-height: calc(100vh - 100px);
  /* Ajusta 100px según la altura que deseas para otros elementos (por ejemplo, encabezado y pie de página) */
  width: 100%;
  /* Asegura que el contenedor ocupe todo el ancho disponible */
  height: 2000px;
  /* Ajusta la altura según sea necesario */
  overflow: auto;
  /* Habilita el desbordamiento con barras de desplazamiento si es necesario */
  padding: 0px 5px 0px 16px;
  margin-top: 17%;
}


.fixed-container::-webkit-scrollbar {
  width: 8px;
  /* Ancho de la pista */
  background-color: transparent;
  /* Fondo de la pista */
}

/* Estilos para el thumb (el elemento que se arrastra) del scroll */
.fixed-container::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* Color del thumb */
  /* border: 2px solid #1983c4; */
  border-radius: 4px;
  /* Borde redondeado del thumb */
  height: 20px;
}


.fixed-container2 {
  position: relative;
  max-height: calc(100vh - 100px);
  /* Ajusta 100px según la altura que deseas para otros elementos (por ejemplo, encabezado y pie de página) */
  width: 100%;
  /* Asegura que el contenedor ocupe todo el ancho disponible */
  height: 2000px;
  /* Ajusta la altura según sea necesario */
  overflow: auto;
  /* Habilita el desbordamiento con barras de desplazamiento si es necesario */
  padding: 0px 6px 0px 6px;
  margin-top: 16%;
}


.fixed-container2::-webkit-scrollbar {
  width: 8px;
  /* Ancho de la pista */
  background-color: transparent;
  /* Fondo de la pista */
}

/* Estilos para el thumb (el elemento que se arrastra) del scroll */
.fixed-container2::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* Color del thumb */
  /* border: 2px solid #1983c4; */
  border-radius: 4px;
  /* Borde redondeado del thumb */
  height: 20px;
}

/* estilos para las cards de mediciones */
.container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  flex-direction: column;
  justify-content: flex-start;
}

.column {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  width: 100%;
}

.row1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 10px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: 10px;
}

.box {
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  width: calc(50% - 10px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 0px;
  text-align: center;
}


.box img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  color: #1983c4;
}

.box p {
  margin-top: 10px;
  font-size: 14px;
  color: #1983c4;
  font-family: 'Segoe UI Variable Static Display 600', sans-serif;
  font-Weight: bold;

}

.disabled {
  margin-top: 10px;
  font-size: 14px;
  color: #9faab2;
}

.box i {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  color: #1983c4;
  font-size: 70px;
}

.iconos {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  color: #1983c4;
  font-size: 100px;
}

.iconosdisabled {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  color: #9faab2;
  font-size: 100px;
}

.container1 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.boxButton {
  border-radius: 40%;
}


h2 {
  text-align: left;
  margin: 0;
  font-family: 'Segoe UI Variable Static Display 700', sans-serif;

}

.indice {
  text-align: left;
  margin: 0;
  margin-top: 22px;
}

.header {
  color: white;
  width: 250px;
}

.tusdatos {
  margin-top: 0%;
  font-family: 'Segoe UI Variable Static Display 700', sans-serif;

}

.boxDatos {
  height: max-content;
}

h1 p {
  font-family: 'Segoe UI Variable Static Display 700', sans-serif;
  font-size: 30px;
}

.nombre {
  font-family: 'Segoe UI Variable Static Display 700', sans-serif;
  font-size: 40px;
}

.containavatar {
  width: 80px;
    /* Ajusta el tamaño del avatar */
    height: 80px;
    /* Ajusta el tamaño del avatar */
    border-radius: 50%;
    /* Hace que el avatar sea circular */
  
    margin-bottom: 60px;
    /* Espacio entre el avatar y el número */
    margin: 0 0 0 80px;
    align-self: flex-end;
}


.containavatar img {
  width: 100%;
    /* Asegura que la imagen ocupe todo el espacio del avatar */
    height: 100%;
    /* Asegura que la imagen ocupe todo el espacio del avatar */
    object-fit: cover;
    /* Ajusta el tamaño de la imagen dentro del avatar */
    border-radius: 50%;
    /* Hace que la imagen sea circular */
    margin: 0 0 0 80px;
}
.Avatar2 img{
  width: 142%;
  /* Asegura que la imagen ocupe todo el espacio del avatar */
  height: 68%;
  /* Asegura que la imagen ocupe todo el espacio del avatar */
  object-fit: cover;
  /* Ajusta el tamaño de la imagen dentro del avatar */
  border-radius: 50%;
  /* Hace que la imagen sea circular */
  margin: 0 0 0 80px;
  margin-left: 185%;
  margin-top: 18%;
}
@media (max-width: 670px) {
 
  .logout-bubble {
    position: relative;
    top: -124%;
    margin-left: 0%;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    width: 171%;
  }  
  .logout-bubble2 {
    position: relative;
    top: -124%;
    margin-left: 0%;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    width: 171%;
  }  
  
}


.containeravatar {
  width: 250px;
  height: 100px;

}

.logout-bubble {
  position: relative;
  top: -124%;
  margin-left: 0%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 171%;
  margin-top: 143%;
}
.logout-bubble2 {
  position: relative;
  top: -124%;
  margin-left: 0%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 171%;
  margin-top: 143%;
}

.logout-option {
  color: #1983c4;
  background-color: inherit;
  border: none
}

.iconlogout {
  font-size: 120%;
  vertical-align: middle;
}

.contenedorheder {
  display: flex;
  flex-direction: row;
  width: 200px;
}

.contenedortusdatos {
  padding: 0px 0px 0px 11px;
}

.version {
  color: white;
}


.iconback{
  margin-top: 24%;
  margin-left: -17%;
}