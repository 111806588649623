body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: 100%;
  /* Cambiado a background-size */
  background-position: center;
  /* overflow: hidden; */
  padding: 0;
  font-family: 'Segoe UI Variable Static Display', sans-serif;
}

code {
  font-family: 'Segoe UI variablevariable';
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0;
  background-image: var(--background-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  overflow: hidden
}

.containerapp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Utilizamos la unidad de viewport height para ocupar toda la altura de la pantalla */
  width: 100%;
  padding: 0;
  background-image: url('./../img/__4-web-.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.inner-container {

  border-radius: 0px;
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); */
  padding: 0px 0px 0px 0px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  border: 1px solid transparent;
}

.inner-container2 {

  border-radius: 0px;
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); */
  padding: 0px 0px 0px 0px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  border: 1px solid transparent;
  height: 105%;
}

.containelogin {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0;
}

input[type="tel"] {
  width: 80%;
  padding: 10px;
  border: 1px solid #27ABE2;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.3s;
  margin-left: 10%;
}


input[type="password"],input[type="email"],input[type="text"] {
  width: 80%;
  padding: 10px;
  border: 1px solid #27ABE2;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.3s;
  margin-left: 10%;
}
input[type="fecha"] {
  width: 90%;
  padding: 10px;
  border: 1px solid #27ABE2;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.3s;
  margin-left: 10%;
}

.botonesrecuperar {
  width: 28%;
  padding: 10px;
  border: 1px solid #27ABE2;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.3s;
  margin-left: 15%;
  background-color: white;
  margin-top: 6%;
  font-size: 11px;
  color: #27ABE2;
  height: 31px;
  text-align: -webkit-center;
}

.contenedorbotonesrec {

  display: flex;
  /* Muestra los botones en línea */


}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  margin-left: 87%;
  margin-bottom: 10%;
  color: #27ABE2;
}

* {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200vh;
}

.MuiTypography-body2 {
  font-size: 0.875rem;
  font-family: 'Segoe UI Variable Static Small 600', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  margin-top: 10%;
}

.MuiTypography-alignCenter {
  text-align: center;
  margin-top: 16%;


}

.textoRecuperar {
  text-align: center;
  font-family: 'Segoe UI Variable Static Small 600', sans-serif;
  margin-top: 10%;
  font-size: 90%;

}

.textoRecuperar2 {
  text-align: center;
  font-family: 'Segoe UI Variable Static Small 600', sans-serif;
  margin-top: 4%;
  font-size: 90%;
  color: #27ABE2
}


.css-twia2z-MuiPaper-root-MuiDialog-paper {
  border-radius: 6%;
}

.versionrecuperar {
  margin-left: -6%;
  margin-top: 21%;
  font-size: 15px;
  margin-bottom: -7%;

}

.versionforgot {
  margin-left: 2%;
  margin-top: 21%;
  font-size: 15px;
  color: #27ABE2;
  margin-bottom: -7%;

}