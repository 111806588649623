 /* estilos para el perfil */
 .date-picker-container {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.date-picker-icon {
  position: absolute;
  right: 10px; /* Ajusta este valor según tu preferencia */
  cursor: pointer;
  z-index: 5000;
  font-size: 150%;
  color:#1983c4;
  margin-top: 3%;
}
 .perfil-container {
   padding: 2px;

   background-size: cover;
   height: 86%;
   width: 93%;

 }
 .MuiGrid-grid-md-true {
  flex-grow: 1;
  max-width: 58%;
  flex-basis: 0;
  margin-left: 8%;
}
 .header {
   display: flex;
   align-items: center;
   background-color: white;
   border-radius: 10px 10px 10px 10px;
   padding: 25px 20px;
   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
   width: 100%;
 }
 .editData {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 10px 10px 10px 10px;
  padding: 25px 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 14%;
  margin-top: 2%;
}
.editData-text{
  text-align: left;
  font-family: 'Segoe UI Variable Static Small 600', sans-serif;
  margin-top: 0%;
  font-size: 121%;
  color: #27ABE2
}
 .avatar {
   width: 80px;
   height: 80px;
   background-color: #0077FF;
   border-radius: 50%;
   margin-right: 20px;
   /* Agrega estilos para el contenido del avatar (por ejemplo, centrado de imagen) */
 }

 .profile-info {
   color: #1983c4;
 }

 .divider {
   height: 1px;
   background-color: #ddd;
   margin: 20px 0;
 }

 .data-section {
   display: flex;
   justify-content: space-between;
   margin-bottom: 20px;
   height: 79px;
 }

 .data-box {
   width: calc(33.33% - 10px);
   padding: 15px;
   border-radius: 10px;
   text-align: center;
   color: white;
 }

 .light-blue {
   background-color: #66B3FF;
   width: calc(33% - 1px);
   font-family: 'Segoe UI Variable Static Display', sans-serif;
   height: 90%;
 }




 .medium-blue {
   background-color: #3399FF;
   font-family: 'Segoe UI Variable Static Display', sans-serif;
   height: 90%;

 }

 .dark-blue {
   background-color: #0077FF;
   font-family: 'Segoe UI Variable Static Display', sans-serif;
   height: 90%;

 }

 .chart-card {
   background-color: white;
   border-radius: 10px;
   padding: 5px;
   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
   /* Ajusta el tamaño para el contenido del gráfico */
 }

 .chart-content {
   background-color: #f2f2f2;
   border-radius: 10px;
   /* Agrega estilos para el contenido del gráfico */
 }

 h1 {
   text-align: left;
   color: white;
   margin-top: 12%;
   width: 100%;

 }

 h3 {
   text-align: left;
   color: #1983c4;
 }

 .perfil {
   margin-top: 37%;
   margin-bottom: 14%;
 }

 p {
   margin: 0;
   font-weight: "bold";
   font-family: 'Segoe UI Variable Static Display 700', sans-serif;


 }

 .numerosTusdatos {
   FONT-WEIGHT: 700;
   font-size: 164%;
 }

 h2 {
   text-align: left;
   margin: 0;

 }

 .h2tusdatos {
   margin-top: 1%;
 }

 .nombreperfil {
   font-family: 'Segoe UI Variable Static Display 700', sans-serif;
   margin-left: 13%;
   white-space: nowrap;
   word-wrap: break-word;
   padding-right: 4%;

 }

 .email {
   font-size: 14px;
   font-family: 'Segoe UI Variable Static Display 700', sans-serif;
   white-space: nowrap;
   word-wrap: break-word;
   margin-left: 13%;
   text-align: left;
   padding-right: 4%;
 }


 .scroll-container {
   overflow: auto;
   max-height: calc(100vh - 100px);
   /* Ajusta la altura máxima del área de desplazamiento según sea necesario */
   background-color: transparent;
   width: 360px;
   width: 101.5%;
   /* Asegura que el contenedor ocupe todo el ancho disponible */
   height: 63%;
   padding: 0% 0% 0% 0%;
 }
 
 .scroll-containerEdit {
  overflow: auto;
  /* Ajusta la altura máxima del área de desplazamiento según sea necesario */
  background-color: transparent;
  width: 360px;
  width: 101.5%;
  /* Asegura que el contenedor ocupe todo el ancho disponible */
  height: 83%;
  padding: 0% 0% 0% 0%;
}

 .scroll-container::-webkit-scrollbar {
   width: 8px;
   /* Ancho de la pista */
   background-color: transparent;
   /* Fondo de la pista */
 }

 /* Estilos para el thumb (el elemento que se arrastra) del scroll */
 .scroll-container::-webkit-scrollbar-thumb {
   background-color: transparent;
   /* Color del thumb */
   /* border: 2px solid #1983c4; */
   border-radius: 4px;
   /* Borde redondeado del thumb */
   height: 20px;
 }

 .icon {
   margin-left: 313%;
   font-size: 260%;
   color: white;
   margin-top: 47%;
 }

 .logout-bubble2 {
   position: absolute;
   top: 13%;
   margin-left: 8%;
   background-color: white;
   border: 1px solid #ccc;
   border-radius: 10px;
   padding: 10px;
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
   z-index: 1;
 }
 .option-list {
  position: absolute;
  top: 80px; /* Ajusta la posición vertical según sea necesario */
  left: -20px; /* Ajusta la posición horizontal hacia la izquierda */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 56%;
    margin-top: 1%;
}

.option-button {
  width: 100%;
  padding: 10px;
  text-align: left;
  cursor: pointer;
  font-family: 'Segoe UI Variable Static Display 700', sans-serif;
  color: #1983c4;
  background-color: transparent;
  border: none;
}



.option-button:hover {
  background-color: #f2f2f2;
}

 .logout-option2 {
   color: #1983c4;
   background-color: inherit;
   border: none
 }

 .iconlogout2 {
   font-size: 120%;
   vertical-align: bottom;
 }

 .containavatar2 {
   width: 80px;
   /* Ajusta el tamaño del avatar */
   height: 80px;
   /* Ajusta el tamaño del avatar */
   border-radius: 50%;
   /* Hace que el avatar sea circular */

   margin-bottom: 60px;
   /* Espacio entre el avatar y el número */
   margin: 0 0 0 80px;
   align-self: flex-end;

 }

 .contenedorheder {
   display: flex;
   flex-direction: row;
   width: 200px;
 }

@media (max-width: 680px) {
  .editData-input{
    width: 80%;
    padding: 10px;
    
    border-radius: 10px;
    outline: none;
    transition: border-color 0.3s;
    margin-left: 10%;
    
    
} 
input[type="perfil"]{
  width: 82%;
padding: 10px;
border: 1px solid #27ABE2;
border-radius: 10px;
outline: none;
transition: border-color 0.3s;
margin-left: 23%;
}

.svg{
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  margin-left: 90%;
  margin-bottom: 20%;
  color: #27ABE2;
  z-index: 500;
}


}
.editData-input{
  width: 80%;
  padding: 10px;
  
  border-radius: 10px;
  outline: none;
  transition: border-color 0.3s;
  margin-left: 10%;
}



@media (min-width: 680px) {

  .svg{
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
    margin-left: 97%;
    margin-bottom: 25%;
    color: #27ABE2;
    z-index: 500;
  }
  .react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 126%;
    margin-left: 0%;
  }
  input[type="perfil"]{
    width: 86%;
  padding: 10px;
  border: 1px solid #27ABE2;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.3s;
  margin-left: 23%;
  }
}
