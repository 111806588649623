.card2 {
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  height: 483px;
}

.card-content2 {
  text-align: center;

}

.grafica-radial-container2 {
  margin-bottom: 0;
 

}

.botones-container2 {
  gap: 10px;
  width: 300%;
  height: 50px;
  margin-left: 0px;
  display: flex;
  /* Cambia display del contenedor a flex */
  justify-content: space-between;
  /* Alinear los botones con espacio entre ellos */


}

.grafica-radial2 {
  width: 300%;
  height: 415px;
  background-color: #f2f2f2;
  border-radius: 10px;
  float: left;



}

.lagrafica number-icon {
  position: relative;
  display: inline-block;
  z-index: 1;
  overflow: hidden;
  margin-top: 35%;
}
.graficaoxi{
  margin-bottom: -7%;
}
.descripcionoxi{
    font-size: 12px;
    font-family: 'Segoe UI Variable Static Display 300', sans-serif;
    text-align: left;
    margin: 0px 12px 0px 3px;
    padding: 5px;
    color: #1983c4;

}

#chart {
  position: absolute;
  z-index: 1;
  /* width: 90%;
  height: 20%; */
  opacity: 0.5;
  /* Ajusta la opacidad según tus preferencias */
  background-color: transparent;
  pointer-events: none;
  margin-left: -5%;
  margin-bottom: -10%,
  
}

.titledescription {
  margin-top: 0%;
  ;
}

.lagrafica {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-left: 5%;
  margin-top: 13%;

}

.contenedortablaoxi{
  margin-top: 7%;
}