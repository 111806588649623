.footer {
  background-color: green;
  border-top: 2px solid red;
  position: fixed;
  width: 100%;
  bottom: 0;
  color: white;
  font-size: 25px;
}

.ButtonMediciones {
  color: #1983c4;
  border-radius: 10px;
  background-color: white;
  padding: 1px 1px;
}

.ButtonMediciones P {
  margin: 0%;
}

.grupoBotones {
  background-color: white;
  padding: 7px 8px;
}

.boton1 {
  border-radius: 20%;
  padding: 7px 8px;
}

.botonescontainer {
  margin-top: 10px;
  margin-left: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 93%;
  /* Ajusta el ancho según tus necesidades */

  margin-block-end: 20px;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

}

.backgroundbotones {

  padding: 2px 2px;
}

.MuiButton-root {
  padding: 1px 1px;
  font-size: 0.875rem;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 10%;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
}

.botones {
  background-color: #f2f2f2;
  color: #1983c4;
  border: none;
  padding: 8px 17px;
  /* Ajusta el padding para hacer los botones más pequeños */
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 10px;
  width: 200px;
  /* Ajusta el ancho del botón */
  text-align: center;
  display: block;
  margin: 3px;


}

.botones:hover {
  background-color: #1983c4;
  color: white;
}

.active-button {
  color: white;
  border: none;
  padding: 8px 17px;
  /* Ajusta el padding para hacer los botones más pequeños */
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 10px;
  width: 200px;
  /* Ajusta el ancho del botón */
  text-align: center;
  display: block;
  margin: 3px;
}


/* estilos para los contenedores de tus datos */
.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;

}

.button-group2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 0px 0px 0px 16px;


}

.buttonHistoricPresion {
  display: flex;
  justify-content: space-between;
  width: 70%;
  height: 55px;
  margin-left: 15%;

}

.button {
  flex: 1;
  background-color: white;
  border: 1px solid #ccc;
  /* Borde de 1px */
  padding: 4px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI variablevariable';
  color: #1983c4;
}

.number {
  font-family: 'Segoe UI Variable Static Display 300', sans-serif;
  font-size: 200%;
  font-weight: bold;
}

.text {
  font-size: 16px;
  font-family: 'Segoe UI Variable Static Display 700', sans-serif;

}

.button1 {
  border-radius: 10px 0 0 10px;
  /* Borde redondeado en el lado izquierdo */
  flex: 1;
  background-color: white;
  border: 1px solid #ccc;
  /* Borde de 1px */
  padding: 4px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI variablevariable';
  color: #1983c4;


}

.buttonUnic {
  border-radius: 10px;
  /* Borde redondeado en el lado izquierdo */
  margin-left: 25%;
  width: 52%;
  background-color: white;
  border: 1px solid #ccc;
  /* Borde de 1px */
  padding: 4px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI variablevariable';
  color: #1983c4;


}

.button3 {
  border-radius: 0 10px 10px 0;
  /* Borde redondeado en el lado derecho */
  flex: 1;
  background-color: white;
  border: 1px solid #ccc;
  /* Borde de 1px */
  padding: 4px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI variablevariable';
  color: #1983c4;

}