.card {
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 4% 4% 4% 4%;
  width: 100%;
  max-width: 600px; /* Agrega un ancho máximo para limitar el tamaño en pantallas grandes */
  height: 484px; /* Usa altura automática para ajustarse al contenido */
}
.cardSolografica {
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 4% 4% 4% 4%;
  width: 100%;
  max-width: 600px; /* Agrega un ancho máximo para limitar el tamaño en pantallas grandes */
  height: 312px; /* Usa altura automática para ajustarse al contenido */
}
.grafica-radial-Solografica {
  width: 100%;
  height: 247px;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding-left: 32px;



}
.card-Historic-IGC {
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 4% 4% 4% 4%;
  width: 100%;
  height: 278px;

}

.apexcharts-toolbar {
  display: none !important;
}

.card-content {
  text-align: center;

}

.grafica-radial-container {
  margin-bottom: 0;


}

@media (max-width: 680px) {
  .circle {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }

  .botones-container {
    gap: 10px;
    width: 88%;
    height: auto; /* Cambia la altura a automática para ajustarse al contenido */
    margin-top: 6%; /* Ajusta el margen superior según sea necesario */
    display: flex;
    justify-content: space-between;
    margin-left:1%;
    
  }

    .card {
      text-align: left;
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 20px;
      margin: 0;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      background-color: white;
      padding: 4% 4% 4% 4%;
      width: 100%;
      max-width: 600px; /* Agrega un ancho máximo para limitar el tamaño en pantallas grandes */
      height: 489px; /* Usa altura automática para ajustarse al contenido */
    }
    .grafica-radial {
      width: 100%;
      height: 450px;
      background-color: #f2f2f2;
      border-radius: 10px;
    
    }
  
}
@media (min-width: 680px) {
  .botones-container {
    gap: 10px;
    width: 94%;
    height: auto; /* Cambia la altura a automática para ajustarse al contenido */
    margin-top: 21%; /* Ajusta el margen superior según sea necesario */
    display: flex;
    justify-content: space-between;
    margin-left:1%;
    
  }
}

.botonesigc,
.botonespresion {
  gap: 10px;
  width: 100%;
  height: 50px;
  margin-left: 0px;
  margin-top: 0%;
  display: flex;
  /* Cambia display del contenedor a flex */
  justify-content: space-between;
  /* Alinear los botones con espacio entre ellos */


}
.botonesimc {
  gap: 10px;
  width: 100%;
  height: 50px;
  margin-left: 0px;
  margin-top: 0%;
  display: flex;
  /* Cambia display del contenedor a flex */
  justify-content: space-between;
  /* Alinear los botones con espacio entre ellos */


}

.grafica-radial {
  width: 100%;
  height: 423px;
  background-color: #f2f2f2;
  border-radius: 10px;

}

.grafica-radial-igc {
  width: 100%;
  height: 415px;
  background-color: #f2f2f2;
  border-radius: 10px;



}

.grafica-radial-historic {
  width: 100%;
  height: 450px;
  background-color: #f2f2f2;
  border-radius: 10px;

}

.grafica-IGC-historic {
  width: 100%;
  height: 248px;
  background-color: #f2f2f2;
  border-radius: 10px;

}

foreignObject[AttributesStyle] {

  width: 337;
  height: 170;
}

.botonescard {
  background-color: #f2f2f2;
  color: #1983c4;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 10px;
  width: 120px;
  height: 40px;
  text-align: center;
  margin-top: 2px;

}

.botonescardDisabled {
  background-color: #f2f2f2;
  color: #909ea9;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 10px;
  width: 109px;
  height: 40px;
  text-align: center;
  margin-top: 2px;
}

.botones:hover {
  background-color: #1983c4;
  color: white;
}

.titledescription {
  font-size: 16px;
  font-family: 'Segoe UI Variable Static Small 700', sans-serif;
  text-align: left;
  color: #1983c4;
  font-Weight: bold;
  margin-top: 0%;
  margin-left: 3%;
}

.titlegraphic {
  font-size: 16px;
  font-family: 'Segoe UI Variable Static Small 700', sans-serif;
  text-align: left;
  color: #1983c4;
  font-Weight: bold;
  margin-top: 0%;
  margin-left: 50%;
}

.titlegraphicPresion {
  font-size: 16px;
  font-family: 'Segoe UI Variable Static Small 700', sans-serif;
  text-align: left;
  color: #1983c4;
  font-Weight: bold;
  margin-top: 0%;
  margin-left: 40%;
}

.titledescription2 {
  font-size: 16px;
  font-family: 'Segoe UI Variable Static Small 700', sans-serif;
  text-align: left;
  color: #1983c4;
  font-Weight: bold;
  margin-left: -1%;
  margin-top: 0%;

}

.descipcionimc {
  font-size: 12px;
  font-family: 'Segoe UI Variable Static Display 300', sans-serif;
  text-align: left;
  margin: 0px 12px 0px -10px;
  padding: 5px;
  color: #1983c4;
}

.descripcion,
.descripcionpresion {
  font-size: 12px;
  font-family: 'Segoe UI Variable Static Display 300', sans-serif;
  text-align: left;
  margin: 0px 12px 0px 2px;
  padding: 5px;
  color: #1983c4;
}

#chart1,
#chart2,
#chart3 {
  float: left;
  width: 33.33%;
}

.water-wave-chart {
  position: relative;
  width: 300px;
  height: 300px;
}

.water-wave-chart .apexcharts-canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.water-wave-chart .apexcharts-data-labels {
  display: none;
}

.water-wave-chart::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(135, 212, 249, 0.2));
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: wave-animation 2s infinite linear;
}

@keyframes wave-animation {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.3;
  }

  50% {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 0.1;
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.3;
  }
}

.botonescardDisabled p {
  font-family: 'Segoe UI Variable Static Text 600', sans-serif;
}

.color-circles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  /* Espacio entre los círculos y la gráfica radial */
}

/* Estilos para un círculo de color */
.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  font-size: 14px;
}

.thecolors {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  margin: 0;
}

.MuiGrid-spacing-xs-1>.MuiGrid-item {
  padding: 4px;
  padding-left: 3%;
}

.MuiGrid-grid-xs-12 {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  margin-left: 3%;
}

.tablagrafica {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  margin-left: 3%;

}

.descrip {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  margin-left: 6%;

}

.descripboton {
  flex-grow: 0;
  max-width: 80%;
  flex-basis: 100%;
  margin-left: 6%;

}

.contenedorcolores {
  display: flex;
  align-items: center;
  /* Centrar verticalmente */
}

.tablaigc {
  width: 100%;
  height: 20%;
  margin-top: -3%;
  position: relative;
}

/* .tablaigc-container {
  position: absolute;
  z-index: 5000; /* Ajusta el valor según sea necesario para superponer la tabla gráfica */
/* margin-top: -3%;
}  */

.MuiGrid-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-top: -8%;
}

.apexcharts-yaxis-label {
  fill: #005aa9;
  /* Cambia este color al que desees */
}